<template>
  <MBCourseCostume10 course-id="8" :items="items"/>
</template>

<script>
import MBCourseCostume10 from "@/components/Membership/Costume/MBCourseCostume10";
import chapters from './data.js';


export default {
  name: "MBCourseHead",
  components: {
    MBCourseCostume10,
  },
  data() {
    return {
      items: chapters
    }
  },
}
</script>

<style>

</style>