<template>
    <KCourse loader-class="MBcont" :course-id="courseId" title="Съемный костюм XIX в." :items="items">
        <div class="MBtextcont">
            <p>Мой топ 5 строчек, которые я используют при шитье костюма для кукол.</p>
            <p>Ручной <b>шов назад иголку,</b> аналог машинной строчки.</p>
        </div>

        <VideoView video-id="718d5dcaf1284c92a4921b2dc0e0fe8f"/>

        <div class="MBtextcont">
            <p><b>Обметочный </b>ручной шов через край. Название говорит само за себя.</p>
        </div>

        <VideoView video-id="fd00654a44bb479e93fd856a0cc4a500"/>

        <div class="MBtextcont">
            <p><b>Ручной шов в сгиб или матрацный</b>. Отлично соединяет края изделия.</p>
        </div>

        <VideoView video-id="876ae7afaeef48b4aae681a1634a8e18"/>
        
        <div class="MBtextcont">
            <p><b>Потайной </b>ручной шов, в основном для обработки нижнего среза изделия или подола.</p>
        </div>

        <VideoView video-id="106a9dc9c61d4a82b5f7c145c7e63be0"/>

        <div class="MBtextcont">
            <p><b>Воздушная петля,</b> для застёжки на пуговицу.</p>
        </div>

        <VideoView video-id="f055faa0180d4eb9a87d24864e1dfd70"/>

    </KCourse>
</template>

<script>
    import VideoView from "../../Widgets/VideoView";
    import KCourse from "../../Widgets/KCourse";

    export default {
        name: "MBCourseHead",
        components: {
            VideoView,
            KCourse,
        },
      props: {
        courseId: {
          type: String,
          default: "4"
        },
        items: {
          type: Array,
          default: [
            {title: "Подготовка", url: "/mycourses/costume/1"},
            {title: "Снимаем выкройку", url: "/mycourses/costume/2"},
            {title: "Моделируем", url: "/mycourses/costume/3"},
            {title: "Ткань. Раскрой", url: "/mycourses/costume/4"},
            {title: "Нижнее белье", url: "/mycourses/costume/5"},
            {title: "Шьем полочку", url: "/mycourses/costume/6"},
            {title: "Дошиваем платье", url: "/mycourses/costume/7"},
            {title: "Красное платье", url: "/mycourses/costume/8"},
            {title: "Делаем обувь", url: "/mycourses/costume/9"},
            {title: "Ручные швы", url: "/mycourses/costume/10"},
            {title: "Машинные швы", url: "/mycourses/costume/11"},
            {title: "Утюжка", url: "/mycourses/costume/12"},
          ]
        }
      }
    }
</script>